<template>
  <v-container id="relatorios-historico" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-microsoft-excel"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Relatórios ::
              <template v-if="relatorio.nome">
                {{ relatorio.nome }}
              </template>
              <v-progress-circular
                v-else
                indeterminate
                size="18"
                color="primary"
              />
            </div>
          </template>

          <v-btn
            id="btn-novo-relatorio"
            color="primary"
            dark
            elevation="1"
            class="mt-5"
            style="float:left"
            relative
            text
            medium
            @click="$router.push('edit')"
          >
            <v-icon left size="30px">
              mdi-plus-circle
            </v-icon>
            Novo relatório
          </v-btn>

          <v-text-field
            id="relatorios-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-relatorios-historico"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <general-progress-bars 
            class="mt-11"
            :items="downloadProgressData" 
            prefixText="Baixando Relatório"
          />

          <v-divider v-if="!loading" class="mt-10" />

          <v-alert
            dense 
            outlined 
            type="info" 
            color="blue" 
            class="mb-0 py-1 px-1"
            v-if="relatorio.descricao && !loading"
          >
            {{ relatorio.descricao }}
          </v-alert>

          <v-divider class="mt-10" />

          <v-data-table
            class="relatorios-historico-table"
            :headers="[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Título',
                value: 'titulo'
              },
              {
                text: 'Criado por',
                value: 'usuario'
              },
              {
                text: 'Executado em',
                value: 'executado_em'
              },
              {
                text: 'N° de Linhas',
                value: 'numero_linhas'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="relatorios"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            no-data-text="Nenhum relatório encontrado"
            no-results-text="Nenhum registro correspondente encontrado"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
          > 
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | addLeadingZero }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <general-status
                id="status-relatorio"  
                :status="item.status" 
              />
            </template>
            <template v-slot:[`item.executado_em`]="{ item }">
              {{ item.executado_em | parseToDateTimeBR }}
            </template>
            <template v-slot:[`item.numero_linhas`]="{ item }">
              <span id="numero-linhas">
                {{ item.numero_linhas | parseNumberToIntegerBR }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                id="btn-download-arquivo-resultado"
                :class="item.status != 'SUCESSO' ? 'cursor-block' : ''"
                :disabled="item.status != 'SUCESSO'"
                color="gray"
                min-width="0"
                class="px-1"
                fab
                icon
                x-small
                :loading="(downloadProgressData[item.id] !== undefined) ? true : false"
                @click="openDialogDownload(item.id)"
              >
                <v-icon
                  small
                >
                  mdi-download
                </v-icon>
              </v-btn>
              <v-btn
                class="px-1 ml-n1"
                :class="item.status == 'EXECUTANDO' ? 'cursor-block' : ''"
                id="btn-edit-relatorio"
                color="gray"
                min-width="0"
                fab
                icon
                x-small
                :disabled="item.status == 'EXECUTANDO'"
                @click="$router.push(`edit/${item.id}`)"
              >
                <v-icon
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="btn-mais-acoes"
                    color="white"
                    class="ml-1"
                    height="22px"
                    width="22px"
                    fab
                    x-small
                    elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      small
                    >
                      mdi mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="item.status === 'SUCESSO'"
                    @click="$router.push(`visualizar/${item.id}`)"
                  >
                    <v-list-item-title>
                      <v-icon
                        small
                      >
                        mdi-eye-outline
                      </v-icon>
                      Visualizar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="podeExecutar(item)"
                    @click="openConfirmationDialog(item)"
                  >
                    <v-list-item-title>
                      <v-icon
                        small
                      >
                        mdi-play
                      </v-icon>
                      Executar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="duplicar(item.id)"
                  >
                    <v-list-item-icon class="mx-0 my-0 py-4">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Duplicar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="podeBaixarBaseBruta(item)"
                    @click="downloadArquivoBaseBruta(item.id)"
                  >
                    <v-list-item-title>
                      <v-icon small>
                        mdi-download
                      </v-icon>
                      Baixar Base Bruta
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="podeBaixarLog(item)"
                    @click="downloadArquivoLog(item.id)"
                  >
                    <v-list-item-title>
                      <v-icon
                        small
                      >
                        mdi-download
                      </v-icon>
                      Baixar Log
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canDeleteItem(item)"
                    @click="openDialogDelete(item.id, item.titulo)"
                  >
                    <v-list-item-title>
                      <v-icon
                        small
                      >
                        mdi-trash-can-outline
                      </v-icon>
                      Deletar
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </base-material-card>

        <dialog-delete 
          :dialog.sync="dialogDelete"
          @submitTriggered="deletarItem(dialogDeleteData.id)"
        >
          <template slot="title">
            Deletar Relatório #{{ dialogDeleteData.id }}
          </template>
          <template slot="body">
            Tem certeza que deseja deletar o relatório <strong>{{ dialogDeleteData.titulo }}</strong>?
          </template>
        </dialog-delete>

        <dialog-download-selector
          :dialog.sync="dialogDownloadSelector"
          @submitTriggered="downloadArquivoResultado(dialogDownloadSelectorData.id, $event)"
        >
          <template slot="title">
            Baixar Relatório #{{ dialogDownloadSelectorData.id }}
          </template>
        </dialog-download-selector>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmationDialog"
      max-width="720"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirmar Importação de Dados
        </v-card-title>
        <v-card-text>
          <v-alert
            dense 
            outlined 
            type="warning" 
            class="mb-1 py-1 px-1"
          >
            <strong>ATENÇÃO</strong>
            <br>A opção <b>Importação de Indicadores para Validação</b> está habilitada, esteja ciente de que quaisquer dados existentes para as Competências selecionadas serão substituídos pelos dados do EQTLINFO.
            <br>Isso também irá executar novamente as validações dos indicadores, qualquer trabalho existente de aprovação de indicadores será perdido.
            <br><br><b>Siga por sua conta e risco.</b>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="confirmationDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="executar(confirmationDialogData)"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import AuthService from '@/services/AuthService';
import RelatoriosService from '@/services/RelatoriosService';
import RelatoriosCriadosService from '@/services/RelatoriosCriadosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [
    refreshDataMixins, 
    tourMixins
  ],
  components: {
    DialogDelete: () => import('@/components/general/DialogDelete'),
    GeneralProgressBars: () => import('@/components/general/GeneralProgressBars.vue'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    DialogDownloadSelector: () => import('@/components/relatorios/DialogDownloadSelector.vue')
  },
  data() {
    return {
      slug: null,
      search: null,
      relatorio: {
        nome: null
      },
      relatorios: [],
      downloadProgressData: {},
      dialogDownloadSelector: false,
      dialogDownloadSelectorData: {},
      dialogDelete: false,
      dialogDeleteData: {
        id: undefined, 
        titulo: undefined
      },
      usuarioAdmin: false,
      arrTour: [
        {
          element: '#btn-novo-relatorio',
          intro: 'Clicando neste botão você poderá cadastrar um novo relatório.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#relatorios-historico-search',
          intro: 'Neste campo você poderá pesquisar por relatórios já cadastrados.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#numero-linhas',
          intro: 'Aqui você consegue visualizar o número de linhas gerados pela execução do relatório.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#status-relatorio',
          intro: 'Aqui você consegue visualizar o status da execução do relatório.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#btn-download-arquivo-resultado',
          intro: 'Clique aqui para efetuar o download do arquivo resultado do relatório.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#btn-edit-relatorio',
          intro: 'Clicando sobre este botão você poderá editar seu relatório.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#btn-mais-acoes',
          intro: 'Clique aqui para visualizar mais ações relacionadas ao relatório, como executar novamente ou excluí-lo.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
      ],
      tourFuncCalled: false,
      loading: false,
      confirmationDialog: false,
      confirmationDialogData: {},
    }
  },
  mounted() {
    this.slug = this.$route.params.slug;
    this.getRelatorio();
    this.getUsuarioAdmin();
  },
  watch: {
    relatorio() {
      this.refreshData(this.getRelatoriosCriados);
    }
  },
  computed: {
    usuario() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    getRelatorio() {
      RelatoriosService
        .getRelatorioBySlug(this.slug)
        .then(relatorio => {
          this.changeBreadcrumb(relatorio.nome);
          this.relatorio = relatorio;
        })
        .catch(err => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar o histórico de relatórios.', '', { position: 'topRight' });
        });
    },
    changeBreadcrumb(nomeRelatorio){
      this.$route.meta.breadcrumb = [
        { text: 'Relatórios', to: '/relatorios', exact: true},
        { text: nomeRelatorio, exact: false }
      ]; 
      //atualiza variavel no store, com isso o watch do appBar atualizar breadcrumb;
      this.$store.commit('WATCH_CHANGE_BREADCRUMB');
    },
    getRelatoriosCriados() {
      this.loading = true;
      RelatoriosCriadosService
        .getRelatoriosCriados(this.relatorio.id)
        .then(relatorios => {
          this.relatorios = [];
          relatorios.forEach(relatorio => {
            relatorio.filtros = JSON.parse(relatorio.filtros);
            this.relatorios.push(relatorio);
          });

          this.iniciarTourPagina();
        })
        .catch(err => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar o histórico de relatórios.', '', { position: 'topRight' });
        })
        .finally(() => this.loading = false);
    },

    podeExecutar(relatorio) {
      return !['EXECUTANDO', 'AGUARDANDO'].includes(relatorio.status);
    },

    openConfirmationDialog(relatorio) {
      if (relatorio.filtros?.importar_indicadores_para_validacao) {
        this.confirmationDialog = true;
        this.confirmationDialogData = relatorio;
      } else {
        this.executar(relatorio);
      }
    },

    executar(relatorio) {
      this.confirmationDialog = false;
      this.confirmationDialogData = {};

      RelatoriosCriadosService
        .executar(relatorio.id)
        .then(() => {
          this.$toast.info('Seu relatório será executado em instantes. Por favor, aguarde.', '', { position: 'topRight' });
          this.getRelatoriosCriados();
        })
        .catch(err => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao agendar execução do relatório.', '', { position: 'topRight' });
        });
    },

    duplicar(id) {
      RelatoriosCriadosService
        .duplicar(id)
        .then(() => {
          this.$toast.success('Relatório duplicado com sucesso.', '', { position:'topRight' });
          this.getRelatoriosCriados();
        })
        .catch(error => {
          this.$toast.error('Erro ao duplicar relatório.', '', { position: 'topRight' });
          console.error(error);
        });
    },

    podeBaixarLog(relatorio) {
      return ['SUCESSO', 'FALHA'].includes(relatorio.status) && this.usuarioAdmin;
    },

    downloadArquivoLog(id) {
      
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2500,
      });

      RelatoriosCriadosService.downloadArquivoLog(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {

          let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id,
          });
        },
      })
      .then(async (response) => {

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', await this.getNomeArquivoLog(id));
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {

        console.error(error);
        this.$toast.error('Erro no download do arquivo.', '', {
          position: 'topRight',
        });
      })
      .finally(() => {
        this.$delete(this.downloadProgressData, id);
      });
    },

    downloadArquivoResultado(id, tipo) {
      RelatoriosCriadosService.getLinkDownloadArquivoResultado(id, tipo)
        .then(res => {
          window.open(res.data.link,'_blank').focus();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Erro no download do arquivo.', '', {
            position: 'topRight',
          });
        })
    },

    downloadArquivoBaseBruta(id, tipo) {
      RelatoriosCriadosService.getLinkDownloadArquivoBaseBruta(id, tipo)
        .then(res => {
          window.open(res.data.link,'_blank').focus();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Erro no download do arquivo.', '', {
            position: 'topRight',
          });
        })
    },

    getNomeArquivoResultado(id) {
      return RelatoriosCriadosService
        .getNomeArquivoResultado(id)
        .then(response => response.data.nome)
        .catch(error => {
          console.error(error)
          this.$toast.error('Erro ao buscar o nome do arquivo.', '', {
            position: 'topRight',
          });
        });
    },

    podeBaixarBaseBruta(item) {
      return (
        item.status === 'SUCESSO' &&
        item.filtros.temas_selecionados_importacao_base_bruta !== undefined && 
        item.filtros?.temas_selecionados_importacao_base_bruta.length > 0
      );
    },

    async getNomeArquivoLog(id) {
      let nomeArquivoResultado = await this.getNomeArquivoResultado(id);
      return `${nomeArquivoResultado}.log`;
    },

    openDialogDelete(id, titulo) {
      this.dialogDeleteData = { 
        id, titulo
      };
      this.dialogDelete = true;
    },

    openDialogDownload(id) {
      this.dialogDownloadSelectorData = { id };
      this.dialogDownloadSelector = true;
    },
    
    deletarItem(id) {
      
      RelatoriosCriadosService
        .delete(id)
        .then(() => {
          this.$toast.success('Relatório removido com sucesso.', '', { position: 'topRight' });
          this.getRelatoriosCriados();
        })
        .catch(err => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao deletar o relatório.', '', { position: 'topRight' });
        });

      this.dialogDelete = false;
    },

    async getUsuarioAdmin() {
      this.usuarioAdmin = await AuthService.userIsAdmin();
    },

    canDeleteItem(item) {
      return item.status !== 'EXECUTANDO' && (this.usuarioAdmin || this.usuario.id === item.usuario_id);
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;
      this.tourFuncCalled = true;
      let arrTourFormatted = this.relatorios.length ? this.arrTour : this.arrTour.splice(0,2);
      this.iniciarTour(arrTourFormatted);
    }
  }    
}

</script>

<style>

@media screen and (min-width: 800px) {
  .relatorios-historico-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .relatorios-historico-table table tr td:nth-child(6) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-relatorios-historico {
    width: 235.86px !important;
  }
}

</style>