import APIService from './APIService';

export default {
  getRelatoriosCriados(tipoRelatorioId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`/relatorios-criados/get-relatorios/${tipoRelatorioId}`)
      .then(response => resolve(response.data.data))
      .catch(error => reject(error));
    });
  },
  getRelatorioCriadoById(id) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`/relatorios-criados/get-by-id/${id}`)
      .then(response => {
        const rData = response.data.data;
        resolve({
          titulo: rData.titulo, 
          filtros: JSON.parse(rData.filtros)
        });
      })
      .catch(error => reject(error));
    });
  },
  getRelatoriosCriadosByStatus(status) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`/relatorios-criados/get-relatorios-by-status/${status}`)
      .then(response => resolve(response.data.data))
      .catch(error => reject(error));
    });
  },
  salvar(id, data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post(`/relatorios-criados/salvar/${id}`, JSON.stringify(data))
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  executar(relatorioId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().patch(`/relatorios-criados/executar/${relatorioId}`)
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  duplicar(relatorioId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().patch(`/relatorios-criados/duplicar/${relatorioId}`)
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  downloadArquivoResultado(relatorioId, tipo, config) {
    return APIService.apiCall().get(`/relatorios-criados/download-arquivo-resultado/${relatorioId}/${tipo}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getLinkDownloadArquivoResultado(relatorioId, tipo) {
    return APIService.apiCall().get(`/relatorios-criados/get-link-download-arquivo-resultado/${relatorioId}/${tipo}`);
  },
  getLinkDownloadArquivoBaseBruta(relatorioId, tipo) {
    return APIService.apiCall().get(`/relatorios-criados/get-link-download-arquivo-base-bruta/${relatorioId}/${tipo}`);
  },
  downloadArquivoLog(relatorioId, config) {
    return APIService.apiCall().get(`/relatorios-criados/download-arquivo-log/${relatorioId}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getNomeArquivoResultado(relatorioId) {
    return APIService.apiCall().get(`/relatorios-criados/get-nome-arquivo-resultado/${relatorioId}`);
  },
  downloadArquivoBaseBruta(relatorioId, config) {
    return APIService.apiCall().get(`/relatorios-criados/download-arquivo-base-bruta/${relatorioId}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getNomeArquivoBaseBruta(relatorioId) {
    return APIService.apiCall().get(`/relatorios-criados/get-nome-arquivo-base-bruta/${relatorioId}`);
  },
  delete(relatorioId) {
    return APIService.apiCall().delete(`/relatorios-criados/delete/${relatorioId}`);
  },
  visualizar(relatorioId) {
    return APIService.apiCall().get(`/relatorios-criados/visualizar/${relatorioId}`);
  }
}