import APIService from './APIService';

export default {
  index() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get('/relatorios/index')
      .then(response => resolve(response.data.data))
      .catch(error => reject(error));
    });
  },
  getCategorias() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get('/relatorios/get-categorias')
      .then(response => resolve(response.data.data))
      .catch(error => reject(error));
    });
  },
  getRelatorioBySlug(slug) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`/relatorios/get-by-slug/${slug}`)
      .then(response => resolve(response.data.data))
      .catch(error => reject(error));
    });
  },
  changeStatusRelatorioById(id, motivo) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post(`/relatorios/changeStatus/${id}`, JSON.stringify(motivo))
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  }
}