export default {
  data: () => ({
    refreshIntervalId: null
  }),
  methods: {
    refreshData(refreshFunction) {
      refreshFunction();  
      this.refreshIntervalId = setInterval(refreshFunction, 30 * 1000);
    },
    cancelRefreshData() {
      clearInterval(this.refreshIntervalId);
    } 
  },
  beforeRouteLeave(to, from, next) {
    this.cancelRefreshData();
    next();
  },
}